.layout-light-menu {
    --menu-bg: #ffffff;
    --root-menuitem-text-color: #293241;

    --menuitem-icon-color: rgb(41, 50, 65);
    --menuitem-text-color: rgb(41, 50, 65);
    --submenu-item-hover-bg-color: var(--surface-hover);

    --popup-submenu-item-hover-bg-color: var(--surface-hover);
    --menuShadow: 0px 10px 40px rgba(41, 50, 65, 0.06);
}
