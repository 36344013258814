/* poppins-regular - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("../../fonts/poppins-v20-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../fonts/poppins-v20-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-500 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../../fonts/poppins-v20-latin-500.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../fonts/poppins-v20-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-600 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../../fonts/poppins-v20-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../fonts/poppins-v20-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-700 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../../fonts/poppins-v20-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../fonts/poppins-v20-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* manrope-200 - latin */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 200;
  src: url("../../fonts/manrope-v15-latin-200.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* manrope-300 - latin */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/manrope-v15-latin-300.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* manrope-regular - latin */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/manrope-v15-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* manrope-500 - latin */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  src: url("../../fonts/manrope-v15-latin-500.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* manrope-600 - latin */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/manrope-v15-latin-600.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* manrope-700 - latin */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/manrope-v15-latin-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* manrope-800 - latin */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/manrope-v15-latin-800.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* manrope-200 - greek */
@font-face {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 200;
    src: url("../../fonts/manrope-v15-greek-200.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* manrope-300 - greek */
  @font-face {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 300;
    src: url("../../fonts/manrope-v15-greek-300.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* manrope-regular - greek */
  @font-face {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    src: url("../../fonts/manrope-v15-greek-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* manrope-500 - greek */
  @font-face {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    src: url("../../fonts/manrope-v15-greek-500.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* manrope-600 - greek */
  @font-face {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    src: url("../../fonts/manrope-v15-greek-600.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* manrope-700 - greek */
  @font-face {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    src: url("../../fonts/manrope-v15-greek-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* manrope-800 - greek */
  @font-face {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 800;
    src: url("../../fonts/manrope-v15-greek-800.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
